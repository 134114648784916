import { fab } from "@fortawesome/free-brands-svg-icons"

import {
  faMapSigns,
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faDownload,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"

export const fontAwesomeLibrary = [
  fab,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faMapSigns,
  faMapMarkerAlt,
  faEnvelope,
  faDownload,
  faLanguage,
]
