import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LangNavButton } from "./elements"
import { LABELS_NAVB } from "./constants/languages"
import logo from "../images/logo_gcg.png"

import "./navb.css"

function Navb({ lang }) {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const routeLinks = [
    {
      to: "/us/",
      name: LABELS_NAVB.US[lang],
    },
    {
      to: "/#serv",
      name: LABELS_NAVB.SERVICES[lang],
    },
    {
      to: "/#method",
      name: LABELS_NAVB.METHODS[lang],
    },
    {
      to: "/clients/",
      name: LABELS_NAVB.CLIENTS[lang],
    },
    {
      to: "/#contact",
      name: LABELS_NAVB.CONTACT[lang],
    },
    {
      to: "/gallery/",
      name: LABELS_NAVB.GALLERY[lang],
    },
  ]
  return (
    <Navbar className="nav_bar " fixed="top" expand="md">
      <NavbarBrand href="/">
        <img src={logo} alt="main logo" className="img-fluid logo" />
      </NavbarBrand>
      <NavbarToggler className=" navbar-light" onClick={toggle} />
      <Collapse className="navcoloritem" isOpen={isOpen} navbar>
        <Nav className="ml-auto text-center" navbar>
          {routeLinks.map(({ to, name }, i) => (
            <NavItem key={i}>
              <NavLink
                className="text-uppercase mx-1 navcoloritem"
                tag={Link}
                to={to}
              >
                {name}
              </NavLink>
            </NavItem>
          ))}
          <LangNavButton />
        </Nav>
        <Nav>
          <NavItem className="">
            <NavLink
              className="navcoloritem"
              href="https://www.facebook.com/GeofisicaCG/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="navcoloritem"
              href="https://twitter.com/GeofisicaCG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="navcoloritem"
              href="https://www.linkedin.com/in/gcg-geof%C3%ADsica-138640139"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="navcoloritem"
              href="https://www.youtube.com/channel/UC-mxAEPqv3nEvVW9jnYYF5g"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="navcoloritem"
              href="https://wa.me/52122258874944"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "whatsapp"]} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="navcoloritem"
              href="mailto:explora@geofisicacg.com"
            >
              <FontAwesomeIcon icon={["far", "envelope"]} />
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default Navb
