/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { library } from "@fortawesome/fontawesome-svg-core"
import { LangContext, langAvailable } from "./globals/lang-context"
import { fontAwesomeLibrary } from "../config/fontawesome"

// import Header from "./header"
import Navb from "./navb"
import { Footer } from "./footer"
import "bootstrap/dist/css/bootstrap.min.css"
import "animate.css"
import "./layout.css"

library.add(...fontAwesomeLibrary)

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [lang, setLang] = useState(langAvailable.es)
  const toggleLang = () => {
    const nextLang =
      lang === langAvailable.es ? langAvailable.en : langAvailable.es
    localStorage.setItem("lang", JSON.stringify(nextLang))
    setLang(nextLang)
  }
  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView()
      }
    }
    const langStorage = JSON.parse(localStorage.getItem("lang"))
    if (langStorage) setLang(langStorage)
  }, [])

  return (
    <>
      <LangContext.Provider
        value={{
          lang,
          toggleLang,
        }}
      >
        <Navb lang={lang} data={data.site.siteMetadata.title} />
        <main className="App">{children}</main>
        <Footer lang={lang} />
      </LangContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
