import React from "react"

export const langAvailable = {
  es: "es",
  en: "en",
}

export const LangContext = React.createContext({
  lang: langAvailable.es,
  toggleLang: () => {},
})
