import React from "react"
import { LangContext } from "../globals/lang-context"
import { NavItem, NavLink } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function LangNavButton() {
  return (
    <LangContext.Consumer>
      {({ lang, toggleLang }) => (
        <NavItem>
          <NavLink onClick={toggleLang} className="navcoloritem">
            <FontAwesomeIcon icon={"language"} />
            &nbsp;{lang}
          </NavLink>
        </NavItem>
      )}
    </LangContext.Consumer>
  )
}

export default LangNavButton
